<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="primary">
        <v-icon color="my_battery_blue"> mdi-alert-circle-outline </v-icon>
        <span class="text-h6 pl-3 white--text"> Restablecer permisos </span>
      </v-card-title>
      <v-card-text class="py-2 px-5">
        <v-container>
          <v-row>
            <v-col cols="12">
              Los permisos de
              <span class="primary--text font-weight-bold">{{
                firstName
              }}</span>
              han sido modificados.
              <p class="pb-0 mb-0 font-weight-bold">
                ¿Deseas restablecerlos a sus valores originales?
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn @click="close" color="support" outlined> volver </v-btn>
        <v-btn @click="save" elevation="0" color="primary">
          si, restablecer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DialogoRestablecer",
  data: function () {
    return {
      dialog: true,
    };
  },
  computed: {
    ...mapState({
      usuario: (state) => state.usuarios.usuario,
    }),
    firstName() {
      if (this.usuario) {
        return this.usuario.sadm_usu_nombre.split(" ")[0];
      }
      return null;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    async save() {
      this.$emit("handle");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
