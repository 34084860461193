<template>
  <div>
    <v-card flat class="soft_shadow">
      <v-card-text>
        <v-breadcrumbs :items="items">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :href="item.href"
              :disabled="item.disabled"
              @click.prevent="goto(item)"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-card-text>
    </v-card>
    <v-row class="pt-4">
      <v-col cols="12" class="">
        <my-welcome />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import myWelcome from "../components/ui/myWelcome";
export default {
  name: "Home",
  components: {
    myWelcome,
  },
  data: () => ({
    items: [],
    menu: 0,
  }),
  methods: {
    goto(item) {
      const location = window.location;
      const url = location.origin.includes("localhost")
        ? process.env.VUE_APP_LOGIN_URL
        : location.origin.replace("accesos", "login");
      switch (item.text) {
        case "Sistemas":
          this.$store.dispatch("config/setBusinessUnit", null);
          window.location.href = `${url}/back?change=sysid`;
          break;
        case "Corporaciones":
          this.$store.dispatch("config/setBusinessUnit", null);
          window.location.href = `${url}/back?change=corid`;
          break;
        case "Empresas":
          this.$store.dispatch("config/setCompany", null);
          window.location.href = `${url}/back?change=empid`;
          break;
        default:
          this.$store.dispatch("config/setEstablishment", null);
          window.location.href = `${url}/back?change=estid`;
          break;
      }
    },
    loadMenus() {
      this.items.push({
        text: "Sistemas",
        disabled: false,
        href: "/",
      });
      this.items.push({
        text: "Corporaciones",
        disabled: this.businessUnit === null,
        href: "/",
      });

      if (!this.businessUnit) {
        this.menu = 1;
        return;
      }

      this.items.push({
        text: "Empresas",
        disabled: this.company === null,
        href: "/",
      });

      if (!this.company) {
        this.menu = 2;
        return;
      }

      this.items.push({
        text: "Establecimientos",
        disabled: this.establishment === null,
        href: "/",
      });
      if (!this.establishment) {
        this.menu = 3;
        return;
      }

      this.items.push({
        text: this.establishmentName,
        disabled: true,
        href: "/",
      });
      this.menu = 4;
    },
    loadBusiness(val) {
      this.items = [
        {
          text: "Corporaciones",
          disabled: val === null,
          href: "/",
        },
      ];
      this.menu = val === null ? 1 : 2;
      this.loadCompany(null);
    },
    loadCompany(val) {
      if (this.businessUnit === null) return;
      this.items = [
        this.items[0],
        {
          text: "Empresas",
          disabled: val === null,
          href: "/",
        },
      ];
      this.menu = val === null ? 2 : 3;
      this.loadEstablishmment(null);
    },
    loadEstablishmment(val) {
      if (!this.company) return;
      this.items = [
        this.items[0],
        this.items[1],
        {
          text: "Establecimientos",
          disabled: val === null,
          href: "/",
        },
      ];
      this.menu = val === null ? 3 : 4;
      if (val !== null) {
        this.items.push({
          text: this.establishmentName,
          disabled: true,
          href: "/",
        });
      }
    },
  },
  mounted() {
    this.loadMenus();
  },
  computed: {
    ...mapState({
      businessUnit: (state) => state.config.businessUnit,
      company: (state) => state.config.company,
      establishment: (state) => state.config.establishment,
      establishmentName: (state) => state.config.establishmentName,
    }),
  },
  watch: {
    businessUnit(val) {
      this.loadBusiness(val);
    },
    company(val) {
      this.loadCompany(val);
    },
    establishment(val) {
      this.loadEstablishmment(val);
    },
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
