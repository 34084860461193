<template>
  <div>
    <my-header-view
      :title="'Modulos de accesos'"
      icon="mdi-folder-multiple-outline"
      text=""
      :bread-crumbs="breadCrums"
    />
    <v-row class="mt-2">
      <v-col cols="12">
        <v-card flat class="soft_shadow">
          <v-row class="pt-5">
            <v-col
              cols="12"
              md="10"
              lg="10"
              class="px-md-16 px-lg-1 offset-lg-1"
            >
              <h2 class="py-4 my_battery_blue--text">Modulos del sistema</h2>
              <p class="text-body-1 support--text">
                Estos son los modulos a los cuales
                <span class="font-weight-medium primary--text">
                  {{ firstName }}
                </span>
                puede tener acceso.
              </p>
            </v-col>
            <v-col
              cols="12"
              md="8"
              lg="6"
              class="offset-md-2 offset-lg-3 d-flex justify-center"
            >
              <v-text-field
                autofocus
                append-icon="mdi-magnify"
                label="Escribe el modulo que buscas"
                outlined
                dense
                clearable
                v-model="searchValue"
                persistent-hint
                hint="Da clic al modulo que deseas acceder"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
              lg="10"
              class="offset-lg-1 pt-0 pb-15 px-md-16 px-lg-1"
            >
              <v-row
                class="scroll-y"
                :style="
                  $vuetify.breakpoint.xl
                    ? 'max-height: 450px'
                    : 'max-height: 385px'
                "
              >
                <v-col
                  cols="12"
                  md="4"
                  lg="3"
                  xl="3"
                  v-for="menu in catalogo"
                  :key="menu.name"
                >
                  <access-card :menu="menu" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import MyHeaderView from "../ui/myHeaderView";
import AccessCard from "../ui/accessCard";
import { mapState } from "vuex";
import { quitarAcentos } from "@/helpers";

export default {
  name: "CatalogoAccesos",
  components: { AccessCard, MyHeaderView },
  data: function () {
    return {
      breadCrums: [
        {
          text: "inicio",
          disabled: false,
          url: "Home",
        },
        {
          text: "Accesos",
          disabled: false,
          url: "GestionarAccesos",
        },
        {
          disabled: true,
          text: "Modulos",
          url: "CatalogoAccesos",
        },
      ],
      searchValue: "",
    };
  },
  computed: {
    ...mapState({
      opciones: (state) => state.opciones.accesos,
      usuario: (state) => state.usuarios.usuario,
      establecimientoInfo: (state) => state.config.establecimientoInfo,
    }),
    firstName() {
      if (this.usuario) {
        return this.usuario.sadm_usu_nombre.split(" ")[0];
      }
      return null;
    },
    modulosRestringidos() {
      let variables = [];
      let generales = ["progra", "inventario pp", "root", "core"];
      for (let clave in this.establecimientoInfo) {
        if (!this.establecimientoInfo[clave]) {
          if (clave === "sadm_est_modulo_pacientes") {
            variables.push("medicos");
          } else {
            variables.push(clave.replace("sadm_est_modulo_", ""));
          }
        }
      }
      return [...variables, ...generales];
    },
    catalogo() {
      let result = [];
      const map = new Map();
      for (const item of this.opciones) {
        if (!map.has(item.sadm_sop_modulo)) {
          map.set(item.sadm_sop_modulo, true);
          result.push({
            name: item.sadm_sop_modulo,
            sadm_sop_modulo_orden: item.sadm_sop_modulo_orden,
            url: "DetalleCatalogoAccesos",
            isModule: true,
            imgUrl: "/img/menus/accesos/permisos_consulta.jpg",
          });
        }
      }
      result = result
        .slice()
        .filter(
          (item) =>
            !this.modulosRestringidos.includes(
              quitarAcentos(item.name.toLowerCase())
            )
        );
      //hacer la validaciones
      if (this.searchValue) {
        return result
          .sort((a, b) => a.sadm_sop_modulo_orden - b.sadm_sop_modulo_orden)
          .filter(
            (item) =>
              item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) >
              -1
          );
      }
      return result.sort(
        (a, b) => a.sadm_sop_modulo_orden - b.sadm_sop_modulo_orden
      );
    },
  },
  watch: {
    usuario: {
      handler() {
        this.goBack();
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      if (!this.usuario) {
        this.$router.push({
          name: "GestionarAccesos",
        });
      }
    },
  },
  mounted() {
    this.goBack();
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped>
.scroll-y {
  overflow-y: scroll;
}
</style>
