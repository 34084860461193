var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('my-header-view',{attrs:{"title":'Permisos Consulta',"icon":"mdi-folder-multiple-outline","text":"","bread-crumbs":_vm.breadCrums}}),_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"soft_shadow",attrs:{"flat":""}},[_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"px-md-16 px-lg-1 offset-lg-1",attrs:{"cols":"12","md":"10","lg":"10"}},[_c('p',{staticClass:"text-body-1 support--text"},[_vm._v(" Estos son los permisos de"),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" consulta ")]),_vm._v(" a los cuales "),_c('span',{staticClass:"font-weight-medium primary--text"},[_vm._v(" "+_vm._s(_vm.firstName)+" ")]),_vm._v(" puede tener acceso. ")])]),_c('v-col',{staticClass:"px-md-16 px-lg-1 offset-lg-1",attrs:{"cols":"12","md":"10","lg":"6"}},[_c('v-switch',{staticClass:"ml-3 pt-0 mt-0",attrs:{"label":"Habilitar todos los grupos de permisos de consulta","inset":"","hide-details":"","color":"my_battery_blue"},on:{"click":_vm.handleSeleccionTodosGrupos},model:{value:(_vm.seleccionarTodosLosGrupos),callback:function ($$v) {_vm.seleccionarTodosLosGrupos=$$v},expression:"seleccionarTodosLosGrupos"}})],1),_c('v-col',{staticClass:"offset-lg-1 px-md-16 px-lg-1",attrs:{"cols":"12","md":"12","lg":"10"}},[_c('v-expansion-panels',{attrs:{"flat":"","popout":"","hover":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.opcionesAgrupadas),function(grupo,indexGroup){return _c('v-expansion-panel',{key:indexGroup,attrs:{"active-class":"grey lighten-5 "}},[_c('v-expansion-panel-header',{attrs:{"color":_vm.panel === indexGroup ? 'primary' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"6","lg":"7"}},[_c('h3',{},[_c('v-icon',{attrs:{"color":_vm.panel === indexGroup
                                ? 'white'
                                : 'my_battery_blue',"left":""}},[_vm._v(" mdi-folder-multiple-outline ")]),_c('span',{class:_vm.panel === indexGroup
                                ? 'white--text'
                                : 'support--text'},[_vm._v(" "+_vm._s(grupo.nombre)+" ")])],1)]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-switch',{staticClass:"pt-0 mt-0",attrs:{"inset":"","hide-details":"","dark":_vm.panel === indexGroup,"color":_vm.panel === indexGroup ? 'white' : 'my_battery_blue'},on:{"click":function($event){return _vm.handleSeleccionGrupo(grupo, indexGroup)}},nativeOn:{"click":function($event){return _vm.preventOpen($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('small',[_vm._v("Habilitar todos")])]},proxy:true}],null,true),model:{value:(grupo.selectionAll),callback:function ($$v) {_vm.$set(grupo, "selectionAll", $$v)},expression:"grupo.selectionAll"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","md":"2","lg":"2"}},[_c('span',{staticClass:"pl-5",class:_vm.panel === indexGroup
                              ? 'white--text'
                              : 'support--text'},[_c('small',[_vm._v(_vm._s(((grupo.countPermisosActivos) + " de " + (grupo.permisos.length))))])])]),_c('v-col',{staticClass:"float-right float-md-left float-lg-right",attrs:{"cols":"12","md":"1","lg":"1"}},[(grupo.editedGroup)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":""},on:{"click":function($event){return _vm.restablecerGrupo(grupo, indexGroup)}},nativeOn:{"click":function($event){return _vm.preventOpen($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.panel === indexGroup ? 'white' : 'support'}},[_vm._v(" mdi-restore ")])],1)]}}],null,true)},[_c('span',[_vm._v(" restablecer grupo")])]):_vm._e()],1)],1)]}},{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.panel === indexGroup ? 'white' : 'support'}},[_vm._v(" $expand ")])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"pt-5"},_vm._l((grupo.permisos),function(item,indexPermisos){return _c('v-col',{key:item.name,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"flat":""}},[_c('v-switch',{attrs:{"color":"my_battery_blue","inset":"","label":("" + (item.sadm_sop_descripcion))},on:{"change":function($event){return _vm.handleSwitchChange(indexPermisos, indexGroup)}},model:{value:(item.userHasPermision),callback:function ($$v) {_vm.$set(item, "userHasPermision", $$v)},expression:"item.userHasPermision"}})],1)],1)}),1)],1)],1)}),1)],1)],1)],1)],1)],1),(_vm.gruposModificados.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","fixed":"","bottom":"","right":"","color":"primary","loading":_vm.loadingUsuario},on:{"click":_vm.guardarPermisos}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-content-save-outline ")])],1)]}}],null,false,775985892)},[_c('span',[_vm._v(" Guardar")])]):_vm._e(),(_vm.gruposModificados.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"80px"},attrs:{"fab":"","fixed":"","bottom":"","right":"","color":"white","disabled":_vm.loadingUsuario},on:{"click":function($event){_vm.dialogRestablecer = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v(" mdi-file-restore-outline ")])],1)]}}],null,false,3078604467)},[_c('span',[_vm._v(" restablecer")])]):_vm._e(),(_vm.dialogCambiosPorGuardar)?_c('dialogo-cambios-por-guardar',{attrs:{"grupos-permisos":_vm.gruposModificados},on:{"handle":_vm.handleDescartarCambios,"close":_vm.closeDialogoCambiosPorGuardar}}):_vm._e(),(_vm.dialogRestablecer)?_c('dialogo-restablecer',{on:{"close":function($event){_vm.dialogRestablecer = false},"handle":function($event){return _vm.restablecerConValoresOriginales()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }