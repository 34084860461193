<template>
  <div class="">
    <my-header-view
      :title="'Gestion de accesos'"
      icon="mdi-folder-multiple-outline"
      text=""
      :bread-crumbs="breadCrums"
    />
    <v-row class="mt-2">
      <v-col cols="12">
        <v-card flat class="soft_shadow">
          <v-row class="pt-5">
            <v-col cols="12" md="10" lg="8" class="offset-md-1 offset-lg-2">
              <h3 class="pb-4 my_battery_blue--text">
                Empleados disponibles para este establecimiento:
              </h3>
              <v-autocomplete
                messages="Busca por nombre o codigo de empleado"
                :items="usuarios"
                v-model="empleadoId"
                :filter="empleadoFilter"
                item-text="sadm_usu_nombre"
                item-value="sadm_usu_id"
                label="Empleado"
                dense
                outlined
                no-data-text="No se encontraron empleados."
                clearable
                :loader-height="5"
                :loading="loadingUsuarios"
                :disabled="loadingUsuarios"
                return-object
                @change="changeUser"
              >
                <template v-slot:selection="data">
                  <small>
                    {{ data.item.sadm_usu_nombre }}
                  </small>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        <small class="font-weight-bold">
                          {{ data.item.sadm_usu_nombre }}
                        </small>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <small>
                          <span class="font-weight-bold">email:</span>
                          {{ data.item.sadm_usu_email }}
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <div>
                <v-row>
                  <v-col cols="12" class="py-5">
                    <v-alert
                      :icon="iconMessage"
                      prominent
                      text
                      color="primary"
                      dense
                    >
                      {{ message }}
                    </v-alert>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row class="pb-10">
            <v-col cols="12" md="12" lg="10" class="px-md-16 offset-lg-1">
              <v-row>
                <v-col
                  class=""
                  cols="12"
                  md="4"
                  v-for="menu in menuItems"
                  :key="menu.name"
                >
                  <access-card :menu="menu" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import myHeaderView from "../ui/myHeaderView";
import AccessCard from "../ui/accessCard";
import { mapActions, mapState } from "vuex";
export default {
  name: "MenuAccesos",
  components: {
    AccessCard,
    myHeaderView,
    /*myMenuCard,*/
  },
  data: function () {
    return {
      empleadoId: null,
      breadCrums: [
        {
          text: "inicio",
          disabled: false,
          url: "Home",
        },
        {
          text: "Accesos",
          disabled: true,
          url: "GestionarAccesos",
        },
      ],
      menuItems: [
        {
          name: "Accesos de usuario",
          imgUrl: "/img/menus/accesos/accesos-2.jpg",
          url: "CatalogoAccesos",
          isModule: false,
        },
        {
          name: "Permisos de usuarios",
          imgUrl: "/img/menus/accesos/permisos.jpg",
          url: "Permisos",
          isModule: false,
        },
        {
          name: "Permisos de consulta",
          imgUrl: "/img/menus/accesos/permisos_consulta.jpg",
          url: "PermisosConsulta",
          isModule: false,
        },
      ],
      loadingEmployees: false,
    };
  },
  computed: {
    ...mapState({
      usuarios: (state) => state.usuarios.usuarios,
      usuario: (state) => state.usuarios.usuario,
      accesosUsuario: (state) => state.usuarios.accesosUsuario,
      permisosUsuario: (state) => state.usuarios.permisosUsuario,
      permisosConsultaUsuario: (state) =>
        state.usuarios.permisosConsultaUsuario,
      loadingUsuarios: (state) => state.usuarios.loading,
      establecimientoInfo: (state) => state.config.establecimientoInfo,
    }),
    updateUserTitle() {
      return this.estado === 0
        ? this.$t("deseaActivarFinca")
        : this.$t("deseaDesactivarFinca");
    },
    loadingPermisos() {
      return this.usuarios.length > 0 && this.loadingUsuarios;
    },
    message() {
      if (!this.usuario) {
        return "Selecciona un empleado para poder continuar";
      }
      if (this.loadingPermisos) {
        return "Estamos obteniendo los permisos del usuario";
      }
      return "Elige la opción que necesites";
    },
    iconMessage() {
      if (!this.usuario) {
        return "mdi-alert-circle-outline";
      }
      if (this.loadingPermisos) {
        return "mdi-cloud-download-outline";
      }
      return "mdi-lightbulb-on-outline";
    },
  },
  methods: {
    ...mapActions({
      setCurrentUser: "usuarios/setCurrentUser",
      getAccesosUsuario: "usuarios/getAccesosUsuario",
    }),
    empleadoFilter(item, queryText) {
      const nombre = item.sadm_usu_nombre.toLowerCase();
      const email = item.sadm_usu_email.toLowerCase();
      const searchText = queryText.toLowerCase();
      return nombre.indexOf(searchText) > -1 || email.indexOf(searchText) > -1;
    },
    changeUser(data) {
      this.setCurrentUser(data);
      this.handleAccesosUsuario();
    },
    async handleAccesosUsuario() {
      const data = {
        query: {
          usuId: +this.empleadoId.sadm_usu_id,
        },
      };
      await this.getAccesosUsuario({ data });
    },
  },
  mounted() {
    this.$store.dispatch("usuarios/findUsuariosCorporaciones");
    if (this.usuario && !this.empleadoId) {
      this.empleadoId = this.usuario;
    }
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped></style>
