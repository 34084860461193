import { apolloProvider } from "../../vue-apollo";
import { findSistemasOpciones } from "../../api/opciones";
import { filtrarOpcionesGlobales } from "@/helpers";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  accesos: [],
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

const actions = {
  findSistemasOpciones({ commit, state, rootState }, force = false) {
    commit("setLoading", true);
    if (state.accesos.length > 0 && !force) {
      commit("setLoading", false);
      return;
    }
    apolloClient
      .query({ query: findSistemasOpciones })
      .then(({ data }) => {
        commit(
          "setAccesos",
          filtrarOpcionesGlobales(
            data.findSistemasOpciones,
            rootState.config.establecimientoInfo
          )
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setAccesos(state, accesos) {
    state.loading = false;
    state.accesos = accesos;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
