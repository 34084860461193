<template>
  <v-card
    @click="gotoRoute(menu)"
    :min-height="maxHeight(menu)"
    :max-height="minHeight(menu)"
    hover
    :color="!usuario ? 'my_grey darken-1' : 'primary'"
    :disabled="!usuario || loadingOpciones || loadingUsuarios"
    :loading="loadingOpciones || loadingUsuarios"
    loader-height="10"
  >
    <v-img
      :src="menu.imgUrl"
      class="white--text ml-auto"
      gradient="to top, rgba(4, 68, 85, 0.9), rgba(4, 68, 85, .40)"
      :min-height="maxHeight(menu)"
      :max-height="minHeight(menu)"
    >
    </v-img>
    <div class="container-name">
      <h2 class="white--text text-xl-h4 text-md-h5 font-weight-medium">
        {{ menu.name }}
      </h2>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "accessCard",
  props: {
    menu: Object,
  },
  computed: {
    ...mapState({
      loadingOpciones: (state) => state.opciones.loading,
      loadingUsuarios: (state) => state.usuarios.loading,
      usuario: (state) => state.usuarios.usuario,
    }),
  },
  methods: {
    maxHeight(menu) {
      if (menu.isModule) {
        return this.$vuetify.breakpoint.lgAndDown ? 150 : 180;
      }
      return this.$vuetify.breakpoint.mdAndDown ? 200 : 250;
    },
    minHeight(menu) {
      if (menu.isModule) {
        return this.$vuetify.breakpoint.lgAndDown ? 150 : 180;
      }
      return this.$vuetify.breakpoint.mdAndDown ? 200 : 250;
    },
    gotoRoute(menu) {
      if (menu.url) {
        let params = {};
        if (menu.isModule) {
          params.modulo = menu.name;
        }
        this.$router.push({
          name: menu.url,
          params: params,
        });
      }
    },
  },
};
</script>
<style scoped>
.container-name {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  width: 80%;
}
</style>
