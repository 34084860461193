<template>
  <v-app class="">
    <v-navigation-drawer
      color="primary darken-2"
      dark
      v-model="sidebarMenu"
      app
      floating
      :permanent="$vuetify.breakpoint.mdAndUp"
      :mini-variant="mini"
      mini-variant-width="85"
      width="174"
    >
      <v-layout column justify-center justify-space-between fill-height>
        <div>
          <v-list-item>
            <v-list-item-title class="text-center">
              <img
                v-if="mini"
                src="/img/icon_mini.png"
                alt="alt"
                width="50"
                class="pt-15"
              />
              <img
                v-else
                src="/img/icon.png"
                alt="alt"
                width="130"
                class="pt-10"
              />
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-list rounded>
          <v-list-item link to="/">
            <v-list-item-icon>
              <v-icon color="">mdi-home-variant-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="">Inicio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon color="">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div>
          <v-list-item>
            <v-list-item-content class="">
              <v-btn
                @click.stop="changeMenu"
                elevation="0"
                block
                color="my_battery_blue"
                class="hidden-sm-and-down"
              >
                <v-icon>
                  {{ toggleMini ? "mdi-chevron-right" : "mdi-chevron-left" }}
                </v-icon>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-layout>
    </v-navigation-drawer>
    <v-app-bar app elevation="0" color="transparent">
      <v-toolbar
        class="mt-7"
        :class="scroll ? 'scrolling' : 'soft_shadow'"
        color="white"
        elevation="4"
        rounded
      >
        <div class="">
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            icon
            @click.stop="sidebarMenu = !sidebarMenu"
          >
            <v-icon>
              {{ !sidebarMenu ? "mdi-forwardburger" : "mdi-backburger" }}
            </v-icon>
          </v-btn>
          <div v-else>
            <v-icon color="primary"> mdi-store </v-icon>
            <strong class="pl-3 support--text">{{ establishmentName }}</strong>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="">
          <v-menu bottom min-width="100px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <v-chip
                outlined
                class="ma-2"
                color="support lighten-5"
                text-color="support"
                v-on="on"
              >
                <v-avatar left color="my_yellow">
                  <span class="white--text">{{ nameInitial }}</span>
                </v-avatar>
                {{ firstName }}
                <v-icon right> mdi-chevron-down </v-icon>
              </v-chip>
            </template>
            <v-card class="pa-5">
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="my_yellow">
                    <span class="white--text text-h6">{{ nameInitial }}</span>
                  </v-avatar>
                  <h5 class="py-3 support--text text--darken-1">{{ name }}</h5>
                  <small class="font-weight-medium primary--text">
                    {{ establishmentName }}
                  </small>
                  <v-divider class="my-3"></v-divider>
                  <v-btn
                    @click="logout"
                    elevation="0"
                    color="secondary"
                    rounded
                  >
                    cerrar sesión
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </div>
      </v-toolbar>
    </v-app-bar>
    <v-main class="">
      <v-container class="px-sm-5 fill-height pb-10" fluid>
        <v-row class="pt-1 fill-height">
          <v-col>
            <transition name="fade">
              <v-dialog
                :value="loading && !showAlert"
                hide-overlay
                persistent
                width="300"
                v-if="loading"
              >
                <v-card color="primary" dark>
                  <v-card-text>
                    Cargando Establecimiento
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <div v-else class="radius-ui">
                <slot />
              </div>
            </transition>
            <v-dialog :value="showAlert" persistent max-width="290">
              <v-card color="warning">
                <v-card-title>
                  <v-icon large left color="white"> mdi-alert </v-icon>
                  <span class="text-h6 font-weight-light white--text">
                    Advertencia
                  </span>
                </v-card-title>
                <v-card-text>
                  <span class="white--text">
                    Seleccione un establecimiento antes de continuar
                  </span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text :href="url">Aceptar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer class="py-3" color="mpla_bg">
      <span class="ml-auto overline">
        &copy; {{ new Date().getFullYear() }} mynube
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PrincipalLayout",
  data: () => ({
    sidebarMenu: false,
    miniVariant: false,
    items: [],
    active: false,
    dialog: false,
    showAlert: false,
    scroll: false,
  }),
  computed: {
    ...mapState({
      toggleMini: (state) => state.config.toggleMini,
      businessUnit: (state) => state.config.businessUnit,
      company: (state) => state.config.company,
      establishment: (state) => state.config.establishment,
      name: (state) => state.config.name,
      loading: (state) => state.config.loading,
      establishmentName: (state) => state.config.establishmentName,
    }),
    mini() {
      return this.$vuetify.breakpoint.smAndDown ? false : this.toggleMini;
    },
    color() {
      return !this.$vuetify.theme.dark ? "grey" : "grey darken-4";
    },
    url() {
      return `${process.env.VUE_APP_LOGIN_URL}/back?change=sysid&sysid=PLAN`;
    },
    nameInitial() {
      return this.name.charAt(0);
    },
    firstName() {
      return this.name.split(" ")[0];
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    changeMenu() {
      this.$store.dispatch("config/changeMenu", !this.toggleMini);
    },
    loadEstablishmment(val) {
      if (val === null && this.company !== null) {
        this.items = [
          {
            title: "Seleccionar Establecimiento",
            href: "/establecimientos",
            icon: "mdi-store",
          },
        ];
        return;
      }
      this.loadMenu();
    },
    loadMenu() {
      if (this.establishment !== null) {
        this.items = [
          {
            title: "Accesos",
            href: "/menu-de-accesos",
            icon: "mdi-fingerprint",
          },
        ];
      }
    },
    logout() {
      localStorage.clear();
      window.open(`${process.env.VUE_APP_LOGIN_URL}/logout`, "_self");
    },
    scrolling() {
      this.scroll =
        document.body.scrollTop > 10 || document.documentElement.scrollTop > 10;
    },
  },
  watch: {
    establishment(val) {
      this.loadEstablishmment(val);
    },
    loading() {
      this.dialog = this.loading;
    },
  },
  created() {
    if (!this.establishment || !this.businessUnit || !this.company) {
      this.showAlert = true;
      return;
    }
    if (this.establishment && this.businessUnit && this.company) {
      this.loadMenu();
      return;
    }
  },
  mounted() {
    window.onscroll = () => {
      this.scrolling();
    };
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped>
.scrolling {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
</style>
