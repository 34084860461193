<template>
  <div>
    <my-header-view
      :title="'Permisos del modulo'"
      icon="mdi-folder-multiple-outline"
      :bread-crumbs="breadCrums"
    />
    <v-row class="my-2">
      <v-col cols="12">
        <v-card flat class="soft_shadow">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
              lg="10"
              class="px-md-16 px-lg-1 offset-lg-1"
            >
              <h2 class="py-4 my_battery_blue--text">{{ modulo }}</h2>
              <p class="text-body-1 support--text">
                Estos son los permisos del modulo
                <span class="font-weight-medium primary--text">
                  {{ modulo }}
                </span>
                a los cuales
                <span class="font-weight-medium primary--text">
                  {{ firstName }}
                </span>
                puede tener acceso.
              </p>
              <v-col cols="12" class="py-5">
                <v-alert
                  :icon="'mdi-lightbulb-on-outline'"
                  prominent
                  text
                  color="primary"
                  dense
                >
                  Expande el grupo de permisos con las flechas y ve a detalle su
                  asignación, ejemplo:
                  <span class="font-weight-bold"> 2 de 10 permisos </span>
                </v-alert>
              </v-col>
            </v-col>
            <v-col
              cols="12"
              md="10"
              lg="6"
              class="px-md-16 px-lg-1 offset-lg-1"
            >
              <v-switch
                @click="handleSeleccionTodosGrupos"
                v-model="seleccionarTodosLosGrupos"
                label="Habilitar todos los grupos de permisos"
                class="ml-3 pt-0 mt-0"
                inset
                hide-details
                color="my_battery_blue"
              ></v-switch>
            </v-col>
            <v-col
              cols="12"
              ccols="12"
              md="12"
              lg="10"
              class="offset-lg-1 px-md-16 px-lg-1"
            >
              <v-expansion-panels v-model="panel" flat popout hover>
                <v-expansion-panel
                  v-for="(grupo, indexGroup) in opcionesAgrupadas"
                  :key="indexGroup"
                  active-class="grey lighten-5 "
                >
                  <v-expansion-panel-header
                    :color="panel === indexGroup ? 'primary' : ''"
                  >
                    <template v-slot:default="{}">
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          lg="7"
                          class="d-flex align-center"
                        >
                          <h3 class="">
                            <v-icon
                              :color="
                                panel === indexGroup
                                  ? 'white'
                                  : 'my_battery_blue'
                              "
                              left
                            >
                              mdi-folder-multiple-outline
                            </v-icon>
                            <span
                              :class="
                                panel === indexGroup
                                  ? 'white--text'
                                  : 'support--text'
                              "
                            >
                              {{ grupo.nombre }}
                            </span>
                          </h3>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                          lg="2"
                          class="d-flex justify-center align-center"
                        >
                          <v-switch
                            class="pt-0 mt-0"
                            v-model="grupo.selectionAll"
                            @click="handleSeleccionGrupo(grupo, indexGroup)"
                            @click.native="preventOpen($event)"
                            inset
                            hide-details
                            :dark="panel === indexGroup"
                            :color="
                              panel === indexGroup ? 'white' : 'my_battery_blue'
                            "
                            :dense="$vuetify.breakpoint.mdAndDown"
                          >
                            <template v-slot:label>
                              <small>Habilitar todos</small>
                            </template>
                          </v-switch>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                          lg="2"
                          class="d-flex justify-center align-center"
                        >
                          <span
                            class="pl-5"
                            :class="
                              panel === indexGroup
                                ? 'white--text'
                                : 'support--text'
                            "
                          >
                            <small>{{
                              `${grupo.countPermisosActivos} de ${grupo.permisos.length} permisos`
                            }}</small>
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                          lg="1"
                          class="float-right float-md-left float-lg-right"
                        >
                          <v-tooltip bottom v-if="grupo.editedGroup">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="restablecerGrupo(grupo, indexGroup)"
                                @click.native="preventOpen($event)"
                                v-bind="attrs"
                                v-on="on"
                                plain
                                icon
                              >
                                <v-icon
                                  :color="
                                    panel === indexGroup ? 'white' : 'support'
                                  "
                                >
                                  mdi-restore
                                </v-icon>
                              </v-btn>
                            </template>
                            <span> restablecer grupo</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:actions>
                      <v-icon
                        :color="panel === indexGroup ? 'white' : 'support'"
                      >
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="pt-5">
                      <v-col
                        class=""
                        cols="12"
                        md="6"
                        v-for="(item, indexPermisos) in grupo.permisos"
                        :key="item.name"
                      >
                        <v-card class="pa-2" flat>
                          <v-switch
                            @change="
                              handleSwitchChange(indexPermisos, indexGroup)
                            "
                            color="my_battery_blue"
                            v-model="item.userHasPermision"
                            inset
                            :label="`${item.sadm_sop_descripcion}`"
                          ></v-switch>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!--boton guardar-->
    <v-tooltip top v-if="gruposModificados.length > 0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="guardarPermisos"
          fab
          fixed
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          :loading="loadingUsuario"
        >
          <v-icon> mdi-content-save-outline </v-icon>
        </v-btn>
      </template>
      <span> Guardar</span>
    </v-tooltip>
    <v-tooltip top v-if="gruposModificados.length > 0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="dialogRestablecer = true"
          fab
          fixed
          bottom
          right
          color="white"
          v-bind="attrs"
          v-on="on"
          style="right: 80px"
          :disabled="loadingUsuario"
        >
          <v-icon color="grey darken-1"> mdi-file-restore-outline </v-icon>
        </v-btn>
      </template>
      <span> restablecer</span>
    </v-tooltip>
    <dialogo-cambios-por-guardar
      v-if="dialogCambiosPorGuardar"
      :grupos-permisos="gruposModificados"
      @handle="handleDescartarCambios"
      @close="closeDialogoCambiosPorGuardar"
    />
    <dialogo-restablecer
      v-if="dialogRestablecer"
      @close="dialogRestablecer = false"
      @handle="restablecerConValoresOriginales()"
    />
  </div>
</template>

<script>
import MyHeaderView from "../../ui/myHeaderView";
import { mapActions, mapState } from "vuex";
import DialogoCambiosPorGuardar from "@/components/accesos/partials/DialogoCambiosPorGuardar";
import DialogoRestablecer from "@/components/accesos/partials/DialogoRestablecer";
import {
  generarBloqueUpdateAccesos,
  gqlBloqueAccesos,
} from "@/helpers/blocksGql";

export default {
  name: "DetalleCatalogoAccesos",
  components: { DialogoRestablecer, DialogoCambiosPorGuardar, MyHeaderView },
  data: function () {
    return {
      panel: 0,
      breadCrums: [
        {
          text: "inicio",
          disabled: false,
          url: "Home",
        },
        {
          text: "Accesos",
          disabled: false,
          url: "GestionarAccesos",
        },
        {
          disabled: false,
          text: "Modulos",
          url: "CatalogoAccesos",
        },
        {
          disabled: true,
          text: "Detalle",
          url: "DetalleCatalogoAccesos",
        },
      ],
      opcionesAgrupadas: [],
      opcionesAgrupadasBackup: [],
      descartarCambios: false,
      toRoute: "",
      dialogCambiosPorGuardar: false,
      gruposModificados: [],
      dialogRestablecer: false,
      seleccionarTodosLosGrupos: false,
    };
  },
  computed: {
    ...mapState({
      opcionesOriginales: (state) => state.opciones.accesos,
      usuario: (state) => state.usuarios.usuario,
      loadingUsuario: (state) => state.usuarios.loadingSave,
      accesosUsuarioOriginales: (state) => state.usuarios.accesosUsuario,
    }),
    firstName() {
      if (this.usuario) {
        return this.usuario.sadm_usu_nombre.split(" ")[0];
      }
      return null;
    },
    opcionesDisponibles() {
      if (this.opcionesOriginales && this.modulo) {
        return this.opcionesOriginales
          .slice()
          .filter((item) => item.sadm_sop_modulo === this.modulo)
          .sort();
      }
      return [];
    },
    modulo() {
      if (this.$route.params.modulo !== undefined) {
        return this.$route.params.modulo;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      handleBloqueUpdateAccesos: "usuarios/handleBloqueUpdateAccesos",
    }),
    preventOpen: function (e) {
      e.cancelBubble = true;
    },
    agrupar() {
      let codigos = this.opcionesDisponibles
        .slice()
        .reduce((acc, item) => {
          if (
            !acc.some(
              (e) =>
                e.sadm_sop_codigo ===
                item.sadm_sop_codigo.substr(0, item.sadm_sop_codigo.length - 4)
            )
          ) {
            acc.push({
              sadm_sop_codigo: item.sadm_sop_codigo.substr(
                0,
                item.sadm_sop_codigo.length - 4
              ),
              sadm_sop_descripcion: item.sadm_sop_descripcion,
            });
          }
          return acc;
        }, [])
        .sort();

      let agrupados = [];
      codigos.forEach((codigo) => {
        let array = this.opcionesDisponibles
          .slice()
          .filter(
            (item) =>
              item.sadm_sop_codigo.substr(
                0,
                item.sadm_sop_codigo.length - 4
              ) === codigo.sadm_sop_codigo
          )
          .map((el) => {
            return {
              ...el,
              userHasPermision: this.verificarPermisoUsuario(el),
              edited: false,
            };
          });
        agrupados.push({
          nombre: codigo.sadm_sop_descripcion,
          selectionAll: false,
          editedGroup: false,
          countPermisosActivos: this.verificarPermisosActivos(array),
          permisos: array,
        });
      });
      this.opcionesAgrupadas = JSON.parse(JSON.stringify(agrupados));
      this.opcionesAgrupadasBackup = JSON.parse(JSON.stringify(agrupados));
    },
    verificarPermisosActivos(permisos) {
      return permisos.filter((item) => item.userHasPermision).length;
    },
    verificarPermisoUsuario(el) {
      let accesoIndividual = this.accesosUsuarioOriginales.find(
        (item) => item.id.toString() === el.sadm_sop_id.toString()
      );
      return accesoIndividual === undefined ? false : accesoIndividual.status;
    },
    handleSwitchChange(indexPermiso, indexGrupo) {
      this.opcionesAgrupadas[indexGrupo].permisos[indexPermiso].edited =
        this.opcionesAgrupadas[indexGrupo].permisos[indexPermiso]
          .userHasPermision !==
        this.opcionesAgrupadasBackup[indexGrupo].permisos[indexPermiso]
          .userHasPermision;
      let permiso = this.opcionesAgrupadas[indexGrupo].permisos[indexPermiso];
      let nombreGrupo = this.opcionesAgrupadas[indexGrupo].nombre;
      this.handlegruposModificados(nombreGrupo, permiso);
    },
    handlegruposModificados(nombreGrupo, permisoData) {
      let grupo = {
        nombre: nombreGrupo,
        permisos: [],
      };
      // si no existe el grupo, lo añado
      if (!this.gruposModificados.some((e) => e.nombre === grupo.nombre)) {
        this.gruposModificados.push(grupo);
        this.handlePermisosModificados(grupo, permisoData);
      } else {
        this.handlePermisosModificados(grupo, permisoData);
      }
    },
    handlePermisosModificados(grupo, permisoData) {
      let indexEnOpcionesAgrupdas = this.opcionesAgrupadas.findIndex(
        (item) => item.nombre === grupo.nombre
      );
      // verifico si existe el grupo
      //obtener el grupo
      let indexGrupoEditado = this.gruposModificados.findIndex(
        (item) => item.nombre === grupo.nombre
      );
      let grupoEditado = this.gruposModificados[indexGrupoEditado];

      let permiso = {
        id: permisoData.sadm_sop_id,
        nombre: permisoData.sadm_sop_descripcion,
        status: permisoData.userHasPermision,
        editado: permisoData.edited,
      };
      // si el permiso no existe lo añado
      if (!grupoEditado.permisos.some((e) => e.id === permiso.id)) {
        grupoEditado.permisos.push(permiso);
      } else {
        //si existe el permiso verifico si no esta editado
        if (!permiso.editado) {
          // elimino el permiso
          grupoEditado.permisos = grupoEditado.permisos.filter(
            (item) => item.id !== permiso.id
          );
        }
      }
      // verifico la longitud de los permisos del grupo
      if (grupoEditado.permisos.length === 0) {
        // si es 0 , elimino el grupo
        this.gruposModificados = this.gruposModificados.filter(
          (item) => item.nombre !== grupoEditado.nombre
        );
      } else {
        //modifico el objeto en el array
        this.gruposModificados[indexGrupoEditado] = grupoEditado;
      }
      this.actualizarOpcionAgrupada(indexEnOpcionesAgrupdas);
      this.verificarEdicionGrupo();
    },
    actualizarOpcionAgrupada(index) {
      this.opcionesAgrupadas[index].countPermisosActivos =
        this.verificarPermisosActivos(this.opcionesAgrupadas[index].permisos);
    },
    verificarCambios(to) {
      if (this.gruposModificados.length > 0) {
        this.dialogCambiosPorGuardar = true;
        this.descartarCambios = false;
        this.toRoute = to.name;
      } else {
        this.descartarCambios = true;
        this.$router.push({ name: to.name });
      }
    },
    handleDescartarCambios() {
      this.descartarCambios = true;
      this.$router.push({ name: this.toRoute });
    },
    closeDialogoCambiosPorGuardar() {
      this.dialogCambiosPorGuardar = false;
      this.descartarCambios = false;
      this.toRoute = "";
    },
    async guardarPermisos() {
      let permisosLimpios = [];
      this.gruposModificados.forEach((el) => {
        permisosLimpios.push(...el.permisos);
      });
      const queryText = generarBloqueUpdateAccesos(
        this.usuario.sadm_usu_id,
        permisosLimpios
      );
      try {
        const mutationObj = gqlBloqueAccesos(queryText);
        await this.handleBloqueUpdateAccesos({ mutationObj });
        await this.handleUpdateAccesosStore(permisosLimpios);
        this.$store.dispatch("alerts/setMessage", {
          message: "Se han actualizado los permisos",
          type: "success",
        });
        this.restablecerPantalla();
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/setMessage", {
          message: "Ha ocurrido un problema al actualizar los permisos",
          type: "error",
        });
      }
    },
    handleUpdateAccesosStore(permisosLimpios) {
      let arr = permisosLimpios.slice().map((item) => {
        return {
          id: item.id,
          status: item.status,
        };
      });
      arr.forEach((item) => {
        this.$store.dispatch("usuarios/updateAccesoUsuario", { data: item });
      });
    },
    restablecerConValoresOriginales() {
      this.opcionesAgrupadas = JSON.parse(
        JSON.stringify(this.opcionesAgrupadasBackup)
      );
      this.seleccionarTodosLosGrupos = false;
      this.gruposModificados = [];
      this.dialogRestablecer = false;
      this.$store.dispatch("alerts/setMessage", {
        message: "Permisos restablecidos",
        type: "info",
      });
    },
    restablecerPantalla() {
      this.gruposModificados = [];
      this.agrupar();
      this.seleccionarTodosLosGrupos = false;
    },
    handleSeleccionGrupo(grupo, indexGrupo) {
      this.actualizarGruposModificados(indexGrupo);
      grupo.permisos.forEach((permiso, indexPermiso) => {
        this.opcionesAgrupadas[indexGrupo].permisos[
          indexPermiso
        ].userHasPermision = grupo.selectionAll;

        let edited =
          permiso.userHasPermision !==
          this.opcionesAgrupadasBackup[indexGrupo].permisos[indexPermiso]
            .userHasPermision;
        this.opcionesAgrupadas[indexGrupo].permisos[indexPermiso].edited =
          edited;
        let permisoParam =
          this.opcionesAgrupadas[indexGrupo].permisos[indexPermiso];
        if (edited) {
          this.handlegruposModificados(grupo.nombre, permisoParam);
        }
      });
      let indexEnOpcionesAgrupdas = this.opcionesAgrupadas.findIndex(
        (item) => item.nombre === grupo.nombre
      );
      this.actualizarOpcionAgrupada(indexEnOpcionesAgrupdas);
      this.verificarEdicionGrupo();
    },
    restablecerGrupo(grupo, indexGrupo) {
      const opciones = [...this.opcionesAgrupadas];
      opciones[indexGrupo] = JSON.parse(
        JSON.stringify(this.opcionesAgrupadasBackup[indexGrupo])
      );
      this.opcionesAgrupadas = opciones;
      this.actualizarGruposModificados(indexGrupo);
      let indexEnOpcionesAgrupdas = this.opcionesAgrupadas.findIndex(
        (item) => item.nombre === grupo.nombre
      );
      this.actualizarOpcionAgrupada(indexEnOpcionesAgrupdas);
      this.verificarEdicionGrupo();
    },
    actualizarGruposModificados(indexGrupo) {
      let nombreGrupo = this.opcionesAgrupadas[indexGrupo].nombre;
      this.gruposModificados = this.gruposModificados.filter(
        (item) => item.nombre !== nombreGrupo
      );
    },
    verificarEdicionGrupo() {
      this.opcionesAgrupadas.forEach((grupo, grupoIndex) => {
        this.opcionesAgrupadas[grupoIndex].editedGroup = false;
        grupo.permisos.forEach((permiso, indexPermiso) => {
          if (
            this.opcionesAgrupadasBackup[grupoIndex].permisos[indexPermiso]
              .userHasPermision !== permiso.userHasPermision
          ) {
            this.opcionesAgrupadas[grupoIndex].editedGroup = true;
          }
        });
      });
    },
    handleSeleccionTodosGrupos() {
      this.opcionesAgrupadas.forEach((grupo, indexGrupo) => {
        this.opcionesAgrupadas[indexGrupo].selectionAll =
          !this.opcionesAgrupadas[indexGrupo].selectionAll;
        this.handleSeleccionGrupo(grupo, indexGrupo);
      });
    },
  },
  mounted() {
    if (!this.usuario) {
      this.$router.push({ name: "GestionarAccesos" });
    }
    this.agrupar();
  },
  beforeRouteLeave(to, from, next) {
    if (this.descartarCambios) {
      next();
    } else {
      this.verificarCambios(to);
    }
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped></style>
