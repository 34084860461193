import { apolloProvider } from "../../vue-apollo";
import {
  findUsuariosCorporaciones,
  getAccesosUsuario,
  updateEmpleado,
  updateEmpleadoPermiso,
} from "../../api/usuarios";

const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  usuarios: [],
  usuario: null,
  loadingSave: false,
  loading: false,
  accesosUsuario: [],
  permisosUsuario: [],
  permisosConsultaUsuario: [],
});

// getters
const getters = {};

// actions
const actions = {
  findUsuariosCorporaciones({ commit, state }, force = false) {
    commit("setLoading", true);
    if (state.usuarios.length > 0 && !force) {
      commit("setLoading", false);
      return;
    }
    apolloClient
      .query({ query: findUsuariosCorporaciones })
      .then(({ data }) => {
        let items = data.findUsuariosCorporaciones.filter(
          (item) => item.srhu_emp_id
        );
        commit("setUsuarios", items);
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  setCurrentUser({ commit }, data) {
    commit("setLoading", true);
    commit("setCurrentUser", data);
  },

  getAccesosUsuario({ commit }, { data }) {
    commit("setLoading", true);
    apolloClient
      .mutate({
        mutation: getAccesosUsuario,
        variables: data,
      })
      .then((response) => {
        commit(
          "setAccesosUsuario",
          response.data.getAccesosUsuario.accesosUsuario
        );
        commit(
          "setPermisosUsuario",
          response.data.getAccesosUsuario.accesosEmpleado
        );
        commit(
          "setPermisosConsultaUsuario",
          response.data.getAccesosUsuario.permisosEmpleado
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },

  handleBloqueUpdateAccesos({ commit }, { mutationObj }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: mutationObj,
        })
        .then(() => {
          resolve(true);
          commit("setLoadingSave", false);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },

  updateAccesoUsuario({ commit }, { data }) {
    commit("setLoadingSave", true);
    commit("updateAccesoUsuario", data);
  },

  //mynube Permisos

  updatePermisoUsuario({ commit }, { data }) {
    commit("setLoadingSave", true);
    commit("updatePermisoUsuario", data);
  },

  updatePermisosEmpleado({ commit }, { id, data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: updateEmpleado, variables: { id, data } })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  //mynube Permisos Consulta

  updatePermisoConsultaUsuario({ commit }, { data }) {
    commit("setLoadingSave", true);
    commit("updatePermisoConsultaUsuario", data);
  },

  updatePermisosConsultaEmpleado({ commit }, { id, data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: updateEmpleadoPermiso, variables: { id, data } })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCurrentUser(state, user) {
    state.usuario = user;
    state.loading = false;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setUsuarios(state, usuarios) {
    state.loading = false;
    state.usuarios = usuarios;
  },
  setAccesosUsuario(state, accesosUsuario) {
    state.loading = false;
    state.accesosUsuario = accesosUsuario.sort((a, b) => a.id - b.id);
  },
  setPermisosUsuario(state, permisosUsuario) {
    state.loading = false;
    state.permisosUsuario = permisosUsuario;
  },
  setPermisosConsultaUsuario(state, permisosConsultaUsuario) {
    state.loading = false;
    state.permisosConsultaUsuario = permisosConsultaUsuario;
  },

  updateAccesoUsuario(state, acceso) {
    const index = state.accesosUsuario.findIndex(
      (item) => item.id.toString() === acceso.id.toString()
    );
    const items = [...state.accesosUsuario];
    items[index] = {
      ...items[index],
      ...acceso,
    };
    state.loadingSave = false;
    state.accesosUsuario = items;
  },

  updatePermisoUsuario(state, permiso) {
    state.permisosUsuario[permiso.id] = permiso.status;
    state.loadingSave = false;
  },

  updatePermisoConsultaUsuario(state, permisoConsulta) {
    state.permisosConsultaUsuario[permisoConsulta.id] = permisoConsulta.status;
    state.loadingSave = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
