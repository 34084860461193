function getMessage({ code, message }) {
  if (code === "UserNotConfirmedException") {
    // this.dialog = true;
  } else if (
    code === "NotAuthorizedException" &&
    message === "Password attempts exceeded"
  ) {
    return "Usuario bloqueado temporalmente!";
  } else {
    return "Usuario o contraseña incorrectos!";
  }
}

export { getMessage };
