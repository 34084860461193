<template>
  <v-card flat class="soft_shadow">
    <v-card-text>
      <v-row class="pt5 px-0">
        <v-col
          cols="12"
          sm="6"
          md="8"
          lg="8"
          align="right"
          class="d-sm-flex align-sm-center justify-sm-end"
        >
          <h1
            class="pa-10 interlineado text-md-h5 text-lg-h4 font-weight-medium"
          >
            ¡ Nos alegra que estes de vuelta
            <span class="primary--text">{{ firstName }}</span> !
          </h1>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
          align="right"
          class="d-sm-flex pb-0 justify-sm-end align-sm-end px-0"
        >
          <img
            src="/img/logo_mynube.png"
            alt="alt"
            :width="$vuetify.breakpoint.mdAndDown ? 200 : 250"
            class=""
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "myWelcome",
  computed: {
    ...mapState({
      name: (state) => state.config.name,
    }),
    firstName() {
      return this.name.split(" ")[0];
    },
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped>
.interlineado {
  line-height: 1.2em;
}
</style>
