import gql from "graphql-tag";

export const getAccesos = gql`
  query getAccesosEstablecimientos($id: ID!) {
    getAccesosEstablecimientos(id: $id) {
      corId
      razon
      siglas
      status
      empresas {
        empId
        razon
        siglas
        establecimientos {
          estId
          razon
          siglas
          direccion
        }
      }
    }
  }
`;

export const getEstablecimiento = gql`
  query getEstablecimiento {
    getEstablecimiento {
      sadm_est_modulo_pacientes
      sadm_est_modulo_fumigadora
      sadm_est_modulo_vidrieria
      sadm_est_modulo_joyeria
      sadm_est_modulo_educacion
      sadm_est_cr_agenda
      sadm_adesd_ve_tarjetas_descuento
      sadm_adesd_ve_control_caja
      sadm_est_modulo_muestras
    }
  }
`;
