export let catalogoPermisos = [
  //Documentos
  {
    sadm_sop_id: "srhu_emp_ve_envios_facturas",
    sadm_sop_descripcion: "Convertir Pedido/Envío a Factura",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_cambia_vendedor",
    sadm_sop_descripcion: "Cambio de vendedor",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_veped_pedidos_con_precio",
    sadm_sop_descripcion: "Pedidos con precio",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_pedidos_totales",
    sadm_sop_descripcion: "Ve Totales en Pedidos",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_revisa_despachos",
    sadm_sop_descripcion: "Convierte Despachos",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_anula_facturas",
    sadm_sop_descripcion: "Anula Facturas de Venta",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_convierte_facturas",
    sadm_sop_descripcion: "Convierte Pedidos a Facturas",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_elimina_facturas",
    sadm_sop_descripcion: "Elimina Facturas de Venta",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_cierra_proyectos",
    sadm_sop_descripcion: "Cierra Proyectos de Venta",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_reabre_proyectos",
    sadm_sop_descripcion: "Reabre Proyectos de Venta",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_aprueba_certificados",
    sadm_sop_descripcion: "Aprueba Certificados",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_revisa_ordenes_compra",
    sadm_sop_descripcion: "Revisa Ordenes de Compra",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_autoriza_ordenes_compra",
    sadm_sop_descripcion: "Autoriza Ordenes de Compra",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_co_anula_facturas",
    sadm_sop_descripcion: "Anula Facturas de Compra",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_co_cambia_numero_factura",
    sadm_sop_descripcion: "Cambia No. de Factura de Compra",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_cofac_confirmar",
    sadm_sop_descripcion: "Confirma Facturas Proveedores",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_autoriza_cheques",
    sadm_sop_descripcion: "Autoriza Cheques",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_conta_maneja_caja_chica",
    sadm_sop_descripcion: "Maneja Caja Chica",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_batra_cerrada",
    sadm_sop_descripcion: "Cierra Transacciones Bancarias",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_badpa_revisado",
    sadm_sop_descripcion: "DPA Revisado",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_fimov_datos_contables",
    sadm_sop_descripcion: "Datos Contables",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_fi_actualiza_financieros",
    sadm_sop_descripcion: "Genera Movimientos Financieros",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_crea_usuarios_clientes",
    sadm_sop_descripcion: "Crea Usuarios de Clientes",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_elimina_graficas",
    sadm_sop_descripcion: "Eliminar Archivos",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_permitido_ingresar_numero_expediente",
    sadm_sop_descripcion: "Ingresar # de Expediente",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ve_control_caja",
    sadm_sop_descripcion: "Permite Control de Caja",
    sadm_sop_modulo: "Documentos",
    mutation: "UpdateEmpleado",
  },
  //inventarios
  {
    sadm_sop_id: "srhu_emp_permitido_unir_productos",
    sadm_sop_descripcion: "Permitido Unir Productos",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_ip_es_proveedor",
    sadm_sop_descripcion: "Es Proveedor",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_permitido_ver_costos",
    sadm_sop_descripcion: "Permitido Ver Costos PT",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_inventariopt_revisado",
    sadm_sop_descripcion: "Inventario PT Revisado",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_inventariopt_fisico_revisado",
    sadm_sop_descripcion: "Inventario PT Fisico Revisado",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_inventariopt_actualizar_existencias",
    sadm_sop_descripcion: "Inventario PT Actualizar Existencias",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_im_permitido_ver_costos",
    sadm_sop_descripcion: "Permitido Ver Costos MP",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_inventariomp_revisado",
    sadm_sop_descripcion: "Inventario MP Revisado",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_inventariomp_fisico_revisado",
    sadm_sop_descripcion: "Inventario MP Fisico Revisado",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
  {
    sadm_sop_id: "srhu_emp_inventariomp_actualizar_existencias",
    sadm_sop_descripcion: "Inventario MP Actualizar Existencias",
    sadm_sop_modulo: "Inventarios",
    mutation: "UpdateEmpleado",
  },
];
//Permisos consulta
export let catalogoPermisosConsulta = [
  // Panel de control
  {
    sadm_sop_id: "srhu_rhepe_exp_pa_ventas",
    sadm_sop_descripcion: "Panel de Ventas Exportar",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_pa_ventas",
    sadm_sop_descripcion: "Panel de Ventas Acciones",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_pa_ventas",
    sadm_sop_descripcion: "Panel de Ventas Totales",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_pa_cxc",
    sadm_sop_descripcion: "Cuentas por Cobrar Exportar",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_pa_cxc",
    sadm_sop_descripcion: "Cuentas por Cobrar Acciones",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_pa_cxc",
    sadm_sop_descripcion: "Cuentas por Cobrar Totales",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_pa_compras",
    sadm_sop_descripcion: "Panel de Compras Exportar",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_pa_compras",
    sadm_sop_descripcion: "Panel de Compras Acciones",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_pa_compras",
    sadm_sop_descripcion: "Panel de Compras Totales",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_pa_cxp",
    sadm_sop_descripcion: "Cuentas por Pagar Exportar",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_pa_cxp",
    sadm_sop_descripcion: "Cuentas por Pagar Acciones",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_pa_cxp",
    sadm_sop_descripcion: "Cuentas por Pagar Totales",
    sadm_sop_modulo: "Panel de control",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Clientes
  {
    sadm_sop_id: "srhu_rhepe_exp_cl_clientes",
    sadm_sop_descripcion: "Clientes Exportar",
    sadm_sop_modulo: "Clientes",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cl_clientes",
    sadm_sop_descripcion: "Clientes Acciones",
    sadm_sop_modulo: "Clientes",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cl_prospectos",
    sadm_sop_descripcion: "Prospectos Exportar",
    sadm_sop_modulo: "Clientes",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cl_prospectos",
    sadm_sop_descripcion: "Prospectos Acciones",
    sadm_sop_modulo: "Clientes",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cl_contactos",
    sadm_sop_descripcion: "Contactos Exportar",
    sadm_sop_modulo: "Clientes",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cl_contactos",
    sadm_sop_descripcion: "Contactos Acciones",
    sadm_sop_modulo: "Clientes",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Ventas
  {
    sadm_sop_id: "srhu_rhepe_exp_ve_cotizaciones",
    sadm_sop_descripcion: "Cotizaciones Exportar",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ve_cotizaciones",
    sadm_sop_descripcion: "Cotizaciones Acciones",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_ve_cotizaciones",
    sadm_sop_descripcion: "Cotizaciones Totales",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ve_pedidos",
    sadm_sop_descripcion: "Pedidos Exportar",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ve_pedidos",
    sadm_sop_descripcion: "Pedidos Acciones",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_ve_pedidos",
    sadm_sop_descripcion: "Pedidos Totales",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ve_facturas",
    sadm_sop_descripcion: "Facturas Exportar",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ve_facturas",
    sadm_sop_descripcion: "Facturas Acciones",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_ve_facturas",
    sadm_sop_descripcion: "Facturas Totales",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ve_envios",
    sadm_sop_descripcion: "Envios Exportar",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ve_envios",
    sadm_sop_descripcion: "Envios Acciones",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_ve_envios",
    sadm_sop_descripcion: "Envios Totales",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ve_proyectos",
    sadm_sop_descripcion: "Proyectos Exportar",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ve_proyectos",
    sadm_sop_descripcion: "Proyectos Acciones",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ve_precios_productos",
    sadm_sop_descripcion: "Precios x Producto Exportar",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ve_productos_facturados",
    sadm_sop_descripcion: "Productos Facturados Exportar",
    sadm_sop_modulo: "Ventas",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Inventario de PT
  {
    sadm_sop_id: "srhu_rhepe_exp_pt_movimientos",
    sadm_sop_descripcion: "Movimientos Exportar",
    sadm_sop_modulo: "Inventario de PT",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_pt_movimientos",
    sadm_sop_descripcion: "Movimientos Acciones",
    sadm_sop_modulo: "Inventario de PT",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_pt_productos",
    sadm_sop_descripcion: "Productos Exportar",
    sadm_sop_modulo: "Inventario de PT",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_pt_productos",
    sadm_sop_descripcion: "Productos Acciones",
    sadm_sop_modulo: "Inventario de PT",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Cuentas por Cobrar
  {
    sadm_sop_id: "srhu_rhepe_exp_cxc_contrasenias",
    sadm_sop_descripcion: "Contraseñas Clientes Exportar",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxc_contrasenias",
    sadm_sop_descripcion: "Contraseñas Clientes Acciones",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxc_contrasenias",
    sadm_sop_descripcion: "Contraseñas Clientes Totales",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxc_cobros",
    sadm_sop_descripcion: "Cobros Exportar",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxc_cobros",
    sadm_sop_descripcion: "Cobros Acciones",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxc_cobros",
    sadm_sop_descripcion: "Cobros Totales",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxc_cobros_anticipos",
    sadm_sop_descripcion: "Cobros Anticipos Exportar",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxc_cobros_anticipos",
    sadm_sop_descripcion: "Cobros Anticipos Acciones",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxc_cobros_anticipos",
    sadm_sop_descripcion: "Cobros Anticipos Totales",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxc_recibos_sin_deposito",
    sadm_sop_descripcion: "Recibos Sin Deposito Exportar",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxc_recibos_sin_deposito",
    sadm_sop_descripcion: "Recibos Sin Deposito Acciones",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxc_recibos_sin_deposito",
    sadm_sop_descripcion: "Recibos Sin Deposito Totales",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxc_estado_cuenta",
    sadm_sop_descripcion: "Estados de Cuenta Exportar",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxc_estado_cuenta",
    sadm_sop_descripcion: "Estados de Cuenta Acciones",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxc_estado_cuenta",
    sadm_sop_descripcion: "Estados de Cuenta Totales",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxc_antiguedad",
    sadm_sop_descripcion: "Antigúedad de Saldos Exportar",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxc_antiguedad",
    sadm_sop_descripcion: "Antigúedad de Saldos Acciones",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxc_antiguedad",
    sadm_sop_descripcion: "Antigúedad de Saldos Totales",
    sadm_sop_modulo: "Cuentas por Cobrar",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Compras
  {
    sadm_sop_id: "srhu_rhepe_exp_co_ordenes_locales",
    sadm_sop_descripcion: "Ordenes Locales Exportar",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_co_ordenes_locales",
    sadm_sop_descripcion: "Ordenes Locales Acciones",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_co_ordenes_locales",
    sadm_sop_descripcion: "Ordenes Locales Totales",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_co_ordenes_importacion",
    sadm_sop_descripcion: "Ordenes Importación Exportar",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_co_ordenes_importacion",
    sadm_sop_descripcion: "Ordenes Importación Acciones",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_co_ordenes_importacion",
    sadm_sop_descripcion: "Ordenes Importación Totales",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_co_facturas",
    sadm_sop_descripcion: "Facturas de Compras Exportar",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_co_facturas",
    sadm_sop_descripcion: "Facturas de Compras Acciones",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_co_facturas",
    sadm_sop_descripcion: "Facturas de Compras Totales",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_co_facturas_caja_chica",
    sadm_sop_descripcion: "Facturas Caja Chica Exportar",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_co_facturas_caja_chica",
    sadm_sop_descripcion: "Facturas Caja Chica Acciones",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_co_facturas_caja_chica",
    sadm_sop_descripcion: "Facturas Caja Chica Totales",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_co_facturas_especiales",
    sadm_sop_descripcion: "Facturas Especiales Exportar",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_co_facturas_especiales",
    sadm_sop_descripcion: "Facturas Especiales Acciones",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_co_facturas_especiales",
    sadm_sop_descripcion: "Facturas Especiales Totales",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_co_productos",
    sadm_sop_descripcion: "Productos Comprados Exportar",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_co_productos",
    sadm_sop_descripcion: "Productos Comprados Acciones",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_co_nover_imagenes_facturas",
    sadm_sop_descripcion: "No ver Imagenes Facturas",
    sadm_sop_modulo: "Compras",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Cuentas por pagar
  {
    sadm_sop_id: "srhu_rhepe_exp_cxp_contrasenias",
    sadm_sop_descripcion: "Contraseñas Proveedores Exportar",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxp_contrasenias",
    sadm_sop_descripcion: "Contraseñas Proveedores Acciones",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxp_pagos",
    sadm_sop_descripcion: "Pagos Exportar",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxp_pagos",
    sadm_sop_descripcion: "Pagos Acciones",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxp_pagos",
    sadm_sop_descripcion: "Pagos Totales",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxp_pagos_anticipos",
    sadm_sop_descripcion: "Pagos Anticipos Exportar",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxp_pagos_anticipos",
    sadm_sop_descripcion: "Pagos Anticipos Acciones",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxp_pagos_anticipos",
    sadm_sop_descripcion: "Pagos Anticipos Totales",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxp_proveedores",
    sadm_sop_descripcion: "Proveedores Exportar",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxp_proveedores",
    sadm_sop_descripcion: "Proveedores Acciones",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxp_estado_cuenta",
    sadm_sop_descripcion: "Estados de Cuenta Exportar",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxp_estado_cuenta",
    sadm_sop_descripcion: "Estados de Cuenta Acciones",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxp_estado_cuenta",
    sadm_sop_descripcion: "Estados de Cuenta Totales",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_cxp_antiguedad",
    sadm_sop_descripcion: "Antigúedad de Saldos Exportar",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_cxp_antiguedad",
    sadm_sop_descripcion: "Antigúedad de Saldos Acciones",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_cxp_antiguedad",
    sadm_sop_descripcion: "Antigúedad de Saldos Totales",
    sadm_sop_modulo: "Cuentas por pagar",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Bancos
  {
    sadm_sop_id: "srhu_rhepe_exp_ba_transacciones",
    sadm_sop_descripcion: "Transacciones Exportar",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ba_transacciones",
    sadm_sop_descripcion: "Transacciones Acciones",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_ba_transacciones",
    sadm_sop_descripcion: "Transacciones Totales",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ba_liquidacion_cobros",
    sadm_sop_descripcion: "Liquidación de Cobros Exportar",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ba_liquidacion_cobros",
    sadm_sop_descripcion: "Liquidación de Cobros Acciones",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_ba_liquidacion_cobros",
    sadm_sop_descripcion: "Liquidación de Cobros Totales",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_ba_caja_chica",
    sadm_sop_descripcion: "Caja Chica Exportar",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_ba_caja_chica",
    sadm_sop_descripcion: "Caja Chica Acciones",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_ba_caja_chica",
    sadm_sop_descripcion: "Caja Chica Totales",
    sadm_sop_modulo: "Bancos",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Finanzas
  {
    sadm_sop_id: "srhu_rhepe_exp_fi_movimientos",
    sadm_sop_descripcion: "Movimientos Exportar",
    sadm_sop_modulo: "Finanzas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_fi_movimientos",
    sadm_sop_descripcion: "Movimientos Acciones",
    sadm_sop_modulo: "Finanzas",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_tot_fi_movimientos",
    sadm_sop_descripcion: "Movimientos Totales",
    sadm_sop_modulo: "Finanzas",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Contabilidad
  {
    sadm_sop_id: "srhu_rhepe_exp_con_nomenclatura",
    sadm_sop_descripcion: "Nomenclatura Exportar",
    sadm_sop_modulo: "Contabilidad",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_con_nomenclatura",
    sadm_sop_descripcion: "Nomenclatura Acciones",
    sadm_sop_modulo: "Contabilidad",
    mutation: "UpdateEmpleadoPermiso",
  },

  //Recursos Humanos
  {
    sadm_sop_id: "srhu_rhepe_exp_rh_empleados",
    sadm_sop_descripcion: "Empleados Exportar",
    sadm_sop_modulo: "Recursos Humanos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_exp_rh_boletas_pago",
    sadm_sop_descripcion: "Boletas de Pago Exportar",
    sadm_sop_modulo: "Recursos Humanos",
    mutation: "UpdateEmpleadoPermiso",
  },
  {
    sadm_sop_id: "srhu_rhepe_acc_rh_boletas_pago",
    sadm_sop_descripcion: "Boletas de Pago Acciones",
    sadm_sop_modulo: "Recursos Humanos",
    mutation: "UpdateEmpleadoPermiso",
  },
];

let idsRestringidos = [
  // PANEL DE VENTAS
  "118", // Resumen de pedidos
  "771", // agregar establecimiento 3

  //  CLIENTES

  "110", // % de Comisiones s/Ventas
  "385", // Clasificación de Precios
  "805", // Puestos Contactos
  "806", // Ver Puesto Contacto
  "807", // Agregar Puesto Contacto
  "808", // Editar Puesto Contacto
  "881", // Distritos
  "882", // Secciones
  "919", // Documentos de venta
  "920", // Ver Documentos de venta
  "921", // Agregar Documentos de venta
  "922", // Editar Documentos de venta
  "954", // Control de documentos clientes
  "955", // Ver control de documentos clientes
  "956", // Agregar control de documentos clientes
  "957", // Editar control de documentos clientes
  "984", // Editar Documentos Certificador
  "985", // Documentos Certificador
  "986", // Ver Documentos Certificador
  "987", // Agregar Documentos Certificador
  "988", // Contratos Certificador
  "989", // Ver Contratos Certificador
  "990", // Agregar Contratos Certificador
  "991", // Editar Contratos Certificador
  "993", // Precios Certificador
  "994", // Ver Precios Certificador
  "995", // Agregar Precios Certificador
  "996", // Editar Precios Certificador

  //  VENTAS

  "643", // Resumen de Ventas por Cliente
  "644", // Resumen de Ventas por Cliente a Excel
  "645", // Imprimir Cotización
  "646", // Convertir Cotización a Pedido
  "653", // Anular Cotización
  "654", // Cambiar Estatus a Decisión Cliente
  "655", // Actualizar Estatus a Aprobada
  "660", // Actualizar Estatus a No Aprobada
  "647", // Imprimir Pedido
  "661", // Convertir Pedido a Factura
  "662", // Anular Pedido
  "663", // Convertir Pedido a Orden de Compra
  "664", // Órdenes de Servicio
  "665", // Nueva Orden de Servicio
  "666", // Actualizar Estatus a Facturado
  "667", // Actualizar Estatus a Grabado
  "668", // Plan de Pedidos
  "669", // Plan de Pedidos para Revisión
  "670", // Detalle de Pedidos con Precio
  "671", // Detalle de Pedidos sin Precio
  "672", // Pedidos Abiertos del Mes
  "648", // Imprimir Factura Electrónica
  "673", // Resumen de Ventas
  "674", // Resumen de Ventas
  "675", // Ventas Por Clientes y Mes
  "676", // Detalle de Ventas
  "677", // Facturas a Excel
  "678", // Resumen de Ventas con Utilidad
  "679", // Detalle de Ventas con Utilidad
  "680", // Facturas Sin Contraseña
  "681", // Facturas Anuladas Diferente Mes
  "682", // Facturas a Archivo CSV
  "683", // Productos sin % de marketing
  "684", // Detalle Comisiones
  "685", // Servicios de Marketing
  "686", // Anular Factura
  "687", // Eliminar Factura
  "688", // Imprimir Factura
  "689", // Copia de Factura Electrónica
  "907", // Cotizacionesdet Ventanas
  "908", // Editar Ventanas
  "923", // Agregar detalle ventana
  "924", // Editar detalle ventana
  "925", // Eliminar detalle ventana
  "926", // Subordenes de Trabajo
  "927", // Ver subordenes de trabajo
  "1001", // Recibir pedidos de woocommerce
  "1024", // Cuotas de préstamos

  // INVENTARIO DE VENTAS

  "781", // Hojas Técnicas
  "782", // Agregar Hoja Técnica
  "783", // Editar Hoja Técnica
  "784", // Certificados
  "785", // Ver Certificado
  "786", // Agregar Certificado
  "787", // Editar Certificado
  "976", // Productos concentrador
  "977", // Ver Productos concentrador
  "978", // Agregar Productos concentrador
  "979", // Editar Productos concentrador

  // COMPRAS
  "961", // Editar mes y año del Iva
  "969", // Solicitudes de compra
  "970", // Ver Solicitudes de compra
  "971", // Agregar Solicitudes de compra
  "972", // Editar Solicitudes de compra
  "973", // Agregar detalle Solicitudes de compra
  "974", // Editar detalle Solicitudes de compra
  "975", // Eliminar detalle Solicitudes de compra
  "980", // Aprobar Solicitudes de compra
  "981", // Autorizar Solicitudes de compra
  "982", // Solicitudes de compra Autorizadas

  // INVENTARIO MP
  "147", // Lista de Movimientos
  "148", // Ver
  "149", // Agregar
  "150", // Editar
  "151", // Agregar detalle
  "152", // Editar detalle
  "153", // Eliminar detalle
  "953", // Cargar Excel Inv. Físico Contenedores
  "223", // Movimientos por Producto
  "397", // Fórmulas PT
  "398", // Ver
  "399", // Agregar
  "400", // Editar
  "442", // Contenedores
  "443", // Ver
  "444", // Agregar
  "445", // Editar
  "650", // Contenedores Vencidos
  "651", // Contenedores No Vencidos
  "652", // Contenedores Sin Fecha de Vencimiento
  "456", // Físico Contenedores
  "492", // Ordenes de Producción
  "493", // Ver
  "494", // Agregar
  "495", // Editar
  "496", // Agregar detalle
  "497", // Editar detalle
  "498", // Eliminar detalle
  "756", // Agregar Orden
  "757", // Editar Orden

  //  CONTABILIDAD

  "434", // List de Polizas
  "435", // Ver
  "436", // Agregar
  "437", // Editar
  "438", // Agregar detalle
  "439", // Editar detalle
  "440", // Eliminar detalle
  "930", // Notas de crédito y débito

  //  ADMINISTRACION

  "70", // Editar Grupo de Usuario
  "878", // Nuevo usuario y grupo
  "78", // Agregar Datos de Usuario
  "79", // Editar datos de Usuario
  "931", // Registrar Usuario Ventas
  "983", // Registrar Usuario Contratos Megaprint
  "992", // Registrar Usuario Proveedor Contratos Megaprint
  "1016", // Cambiar contraseña
  "1023", // Registrar usuario PAF Megaprint
  "113", // Búsqueda por Nombre
  "216", // Búsqueda por Documento
  "217", // Lista de Impresoras
  "218", // Agregar
  "219", // Editar
  "220", // Lista Formatos Documento
  "221", // Agregar
  "222", // Editar Encabezado Factura
  "269", // Editar Productos Factura
  "270", // Editar Pie de Factura
  "271", // Editar Campos Adicionales
  "952", // Editar Datos Gobierno
  "1015", // Editar Datos Seguro
  "259", // Accesos Panel de Control
  "272", // Accesos Clientes
  "273", // Accesos Médicos
  "274", // Accesos Ventas
  "275", // Accesos Cuentas Cobrar
  "276", // Accesos Inventario Ventas
  "277", // Accesos Compras
  "278", // Accesos Cuentas Pagar
  "279", // Accesos Inventario Compras
  "280", // Accesos Bancos
  "281", // Accesos Contabilidad
  "282", // Accesos Recursos Humanos
  "283", // Accesos Finanzas
  "373", // Accesos Inv. Materia Prima
  "374", // Accesos Administración
  "509", // Accesos Inv. en Proceso
  "510", // Accesos Root
  "809", // Accesos Vidriería
  "810", // Accesos Educación
  "519", // Reportes
  "520", // Agregar
  "521", // Editar
  "531", // Configuración de Opciones del sistema
  "532", // Ver
  "533", // Agregar
  "534", // Editar
  "656", // Opciones de los menús
  "657", // Ver
  "658", // Agregar
  "659", // Editar
  "811", // Accesos Vidriería
  "812", // Accesos Educación
  "813", // Accesos Joyería
  "998", // Establecimientos Distribuidores
];

let ids_sadm_est_modulo_muestras = [
  "12", // Lista de muestras
  "13", // Ver
  "14", // Agregar
  "15", // Editar
  "114", // Agregar detalle
  "115", // Editar detalle
  "116", // Eliminar detalle
  "928", // Editar datos persona recibe
];

let ids_sadm_est_modulo_fumigadora = [
  "393", // Sucursales
  "394", // Ver Sucursal
  "395", // Agregar Sucursal
  "396", // Editar Sucursal
  "527", // Centros de Clientes
  "528", // Ver
  "529", // Agregar
  "530", // Editar
  "527", // Centros de Clientes
  "528", // Ver
  "529", // Agregar
  "530", // Editar
  "607", // Subcentros por Cliente
  "608", // Ver
  "609", // Agregar
  "610", // Editar
  "740", // Contratos de Clientes
  "741", // Ver
  "742", // Agregar
  "743", // Editar
];

let ids_sadm_est_cr_agenda = [
  "909", // Agenda Clientes
  "910", // Ver Cita
  "911", // Agregar Cita
  "912", // Editar Cita
  "913", // Agregar Evento
  "914", // Editar Evento
];

let ids_sadm_adesd_ve_tarjetas_descuento = [
  "1010", // Tarjetas de descuento
  "1029", // Ver Tarjetas de Descuento
  "1030", // Editar Tarjetas de Descuento
];

let ids_sadm_adesd_ve_control_caja = [
  "1018", // Cortes de Caja
  "1019", // Ver corte de caja
  "1020", // Agregar corte de caja
  "1021", // Editar corte de caja
  "1022", // Control de Caja
];

export function filtrarOpcionesGlobales(arrayOpciones, establecimientoInfo) {
  let idGlobals = [].concat([], idsRestringidos);
  let control = {
    sadm_est_modulo_muestras: ids_sadm_est_modulo_muestras,
    sadm_est_modulo_fumigadora: ids_sadm_est_modulo_fumigadora,
    sadm_est_cr_agenda: ids_sadm_est_cr_agenda,
    sadm_adesd_ve_tarjetas_descuento: ids_sadm_adesd_ve_tarjetas_descuento,
    sadm_adesd_ve_control_caja: ids_sadm_adesd_ve_control_caja,
  };

  for (let key in establecimientoInfo) {
    // eslint-disable-next-line no-prototype-builtins
    if (control.hasOwnProperty(key)) {
      idGlobals = [].concat(idGlobals, control[key]);
    }
  }
  let arr = arrayOpciones.filter(function (item) {
    return !idGlobals.includes(item.sadm_sop_id);
  });

  const items = [...arr];
  let indexEd1 = arr.findIndex((item) => item.sadm_sop_id === "359");
  let indexEd2 = arr.findIndex((item) => item.sadm_sop_id === "360");
  items[indexEd1] = { ...arr[indexEd2] };
  items[indexEd2] = { ...arr[indexEd1] };
  arr = items;

  return arr.filter(function (item) {
    return !idGlobals.includes(item.sadm_sop_id);
  });
}
