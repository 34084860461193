import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config.js";
import alerts from "./modules/alerts.js";
import opciones from "./modules/opciones";
import usuarios from "./modules/usuarios";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    alerts,
    opciones,
    usuarios: usuarios,
  },
});
