import gql from "graphql-tag";

export const generarBloqueUpdateAccesos = (empleadoId, accesos) => {
  let query = "";
  let i = 1;
  for (let acceso of accesos) {
    const accesoItem = `acceso${i}`;
    query += `
            ${accesoItem}: updateUsuarioOpcion(
              data: {
                usuId: ${empleadoId}
                sopId: ${acceso.id}
                acceso: ${acceso.status}
              }
            )
          `;
    i++;
  }
  return query;
};

export function gqlBloqueAccesos(queryText) {
  return gql`
        mutation {
            ${queryText}
        }
    `;
}
