import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { GetToken, SetTokens } from "@/core/auth";
import { checkToken } from "@/core/helpers";
// import { onLogout } from "../vue-apollo";
import store from "../store/";

Vue.use(VueRouter);
async function requireAuth(to, from, next) {
  try {
    const token = GetToken();
    if (checkToken(token)) next();
    else throw "Token invalido";
  } catch (error) {
    localStorage.clear();
    window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
  }
}

async function checkSession(to, from, next) {
  try {
    const token = GetToken();
    if (checkToken(token)) router.push({ name: "Home" });
    else throw "Token invalido";
  } catch (error) {
    localStorage.clear();
    next();
  }
}

async function setTokens(to) {
  SetTokens(to.params);
  const jsonPayload = window.atob(to.params.estData);
  store.dispatch("config/setEst", JSON.parse(jsonPayload), to.params.idToken);
  router.push({ name: "Home" });
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: "/gestionar-accesos",
    component: () => import("../views/accesos/Accesos.vue"),
    children: [
      {
        path: "/menu-de-accesos",
        name: "GestionarAccesos",
        component: () => import("../components/accesos/MenuAccesos.vue"),
      },
      {
        path: "/menu-de-accesos/accesos-de-usuario/",
        component: () => import("../views/accesos/CatalogoAccesos"),
        children: [
          {
            path: "",
            name: "CatalogoAccesos",
            component: () =>
              import("../components/accesos/CatalogoAccesos.vue"),
          },
          {
            path: "detalle-acceso",
            name: "DetalleCatalogoAccesos",
            component: () =>
              import(
                "../components/accesos/partials/DetalleCatalogoAccesos.vue"
              ),
          },
        ],
      },
      {
        path: "/menu-de-accesos/permisos-de-usuario/",
        component: () => import("../views/permisos/Permisos.vue"),
        children: [
          {
            path: "",
            name: "Permisos",
            component: () => import("../components/permisos/Permisos.vue"),
          },
        ],
      },
      {
        path: "/menu-de-accesos/permisos-consulta-de-usuario/",
        component: () => import("../views/permisos/Permisos.vue"),
        children: [
          {
            path: "",
            name: "PermisosConsulta",
            component: () =>
              import("../components/permisosConsulta/PermisosConsulta.vue"),
          },
        ],
      },
    ],
    beforeEnter: requireAuth,
  },
  {
    path: "/auth",
    component: () => import("../views/Auth.vue"),
    children: [
      {
        path: "",
        name: "Auth",
        component: () => import("@/components/Auth/Login"),
        meta: {
          layout: "LoginLayout",
        },
      },
      {
        path: "change",
        name: "ChangePassword",
        component: () => import("@/components/Auth/ChangePassword"),
        meta: {
          layout: "LoginLayout",
        },
        props: (route) => ({
          ...route.params,
        }),
      },
      {
        path: "recover",
        name: "Recover",
        component: () => import("@/components/Auth/RecoverAccount"),
        meta: {
          layout: "LoginLayout",
        },
      },
    ],
    beforeEnter: checkSession,
  },
  {
    path: "/oauth/:user/:accesToken/:refreshToken/:idToken/:estData",
    name: "OAuth",
    beforeEnter: setTokens,
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter() {
      localStorage.clear();
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}/logout`;
      // router.push({ name: "Auth" });
      // onLogout();
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
