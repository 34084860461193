import gql from "graphql-tag";

export const findSistemasOpciones = gql`
  query {
    findSistemasOpciones {
      sadm_sop_id
      sadm_sop_codigo
      sadm_sop_descripcion
      sadm_sop_descripcion_larga
      scor_scmod_id
      scor_scmod_id_menu
      sadm_sop_modulo
      sadm_sop_modulo_orden
      sadm_sop_texto_opcion
      sadm_sop_atajo_teclado_opcion
      sadm_sop_opcion_orden
      sadm_sop_tipo_menu
      sadm_sop_columna_menu
      sadm_sop_orden_columna_menu
      sadm_sop_parametros_menu
      sadm_sop_opcion_interna
      sadm_sop_opcion_accion
      sadm_sop_id_menu_exportar
      sadm_sop_tipo_menu_exportar
      sadm_sop_columna_menu_exportar
      sadm_sop_orden_columna_menu_exportar
      scor_sctab_tipos_status
      sadm_sop_carpeta_modulo
      sadm_sop_carpeta_opcion
      sadm_sop_clase
      sadm_sop_columna
      sadm_sop_acceso_general
      sadm_sop_permite_editar
      sadm_sop_agrupa_opciones
      sadm_sop_agregar
      sadm_sop_editar
      sadm_sop_ver
      sadm_sop_tiene_detalle
      sadm_sop_tipo_documento
      sadm_sop_url_youtube
    }
  }
`;
