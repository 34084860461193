<template>
  <router-view />
</template>
<script>
export default {
  name: "Permisos",
};
</script>

<style scoped></style>
