<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="primary">
        <v-icon color="my_battery_blue"> mdi-alert-circle-outline </v-icon>
        <span class="text-h6 pl-3 white--text">
          Existen cambios en los permisos
        </span>
      </v-card-title>
      <v-card-text class="py-2 px-5">
        <v-container>
          <v-row>
            <v-col cols="12">
              Has modificado los permisos de
              <span class="primary--text font-weight-bold">{{
                firstName
              }}</span>
              para este modulo.
              <p class="pb-0 mb-0 font-weight-bold">
                ¿Deseas continuar sin guardar los cambios?
              </p>
            </v-col>
          </v-row>
          <v-row style="max-height: 200px; overflow-y: auto">
            <v-col
              cols="12"
              v-for="(grupo, index) in gruposPermisos"
              :key="index"
              class="px-10"
            >
              <h3 class="primary--text">{{ `${grupo.nombre}` }}</h3>
              <ul>
                <li v-for="item in grupo.permisos" :key="item.nombre">
                  <span>
                    {{ item.nombre }}
                  </span>
                  <v-chip
                    x-small
                    label
                    :color="item.status ? 'primary' : 'secondary'"
                    class="float-right"
                  >
                    {{ `${item.status ? "Habilitado" : "Deshabilitado"}` }}
                  </v-chip>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn @click="close" color="support" outlined> volver </v-btn>
        <v-btn @click="save" elevation="0" color="primary">
          si, descartar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DialogoCambiosPorGuardar",
  props: {
    gruposPermisos: Array,
  },
  data: function () {
    return {
      dialog: true,
    };
  },
  computed: {
    ...mapState({
      usuario: (state) => state.usuarios.usuario,
    }),
    firstName() {
      if (this.usuario) {
        return this.usuario.sadm_usu_nombre.split(" ")[0];
      }
      return null;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    async save() {
      this.$emit("handle");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
