import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#1A57A8",
        secondary: "#FFCA28",
      },
      light: {
        primary: "#1A57A8",
        my_yellow: "#FFCA28",
        my_battery_blue: "#27BAE2",
        my_soft_blue: "#508DF5",
        my_cyan: "#044455",
        my_grey: "#F1F0FB",
        support: colors.grey.darken2,
        custom_blue_app_bar: "#0495f1",
      },
    },
  },
});
