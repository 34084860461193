import gql from "graphql-tag";

export const findUsuariosCorporaciones = gql`
  query {
    findUsuariosCorporaciones {
      sadm_uco_id
      sadm_usu_id
      sadm_gus_id
      sadm_uco_tipo_acceso
      sadm_uco_status
      sadm_uco_fecha_alta
      sadm_uco_fecha_baja
      sadm_usu_email
      sadm_usu_nombre
      srhu_emp_id
    }
  }
`;

export const getAccesosUsuario = gql`
  query ($query: GetAccesosUsuariosQuery!) {
    getAccesosUsuario(query: $query) {
      accesosUsuario {
        id
        status
      }
      accesosEmpleado {
        srhu_emp_aprueba_certificados
        srhu_emp_revisa_ordenes_compra
        srhu_emp_autoriza_ordenes_compra
        srhu_emp_autoriza_cheques
        srhu_emp_permitido_ver_costos
        srhu_emp_permitido_unir_productos
        srhu_emp_permitido_ingresar_numero_expediente
        srhu_emp_inventariopt_revisado
        srhu_emp_inventariopt_fisico_revisado
        srhu_emp_inventariomp_revisado
        srhu_emp_inventariomp_fisico_revisado
        srhu_emp_inventariopt_actualizar_existencias
        srhu_emp_inventariomp_actualizar_existencias
        srhu_emp_conta_maneja_caja_chica
        srhu_emp_cofac_confirmar
        srhu_emp_batra_cerrada
        srhu_emp_badpa_revisado
        srhu_emp_ip_es_proveedor
        srhu_emp_fimov_datos_contables
        srhu_emp_veped_pedidos_con_precio
        srhu_emp_ve_envios_facturas
        srhu_emp_ve_cambia_vendedor
        srhu_emp_ve_anula_facturas
        srhu_emp_ve_elimina_facturas
        srhu_emp_ve_cierra_proyectos
        srhu_emp_ve_reabre_proyectos
        srhu_emp_ve_despacha_pedidos
        srhu_emp_ve_revisa_despachos
        srhu_emp_ve_pedidos_totales
        srhu_emp_ve_convierte_facturas
        srhu_remp_ve_recibe_vehiculo
        srhu_emp_ve_control_caja
        srhu_emp_co_anula_facturas
        srhu_emp_co_visualiza_solicitudes_1
        srhu_emp_co_visualiza_solicitudes_2
        srhu_emp_co_cambia_depto_compras
        srhu_emp_co_aprueba_solicitudes
        srhu_emp_co_autoriza_solicitudes
        srhu_emp_co_cambia_numero_factura
        srhu_emp_in_usuario_inventario
        srhu_emp_im_permitido_ver_costos
        srhu_emp_fi_actualiza_financieros
        srhu_emp_modifica_datos_empleados
        srhu_emp_crea_usuarios_clientes
        srhu_emp_elimina_graficas
      }
      permisosEmpleado {
        srhu_rhepe_ve_enviar_pdf_email
        srhu_rhepe_ve_enviar_pdf_email
        srhu_rhepe_ve_solo_sus_facturas
        srhu_rhepe_co_nover_imagenes_facturas
        srhu_rhepe_exp_pa_ventas
        srhu_rhepe_acc_pa_ventas
        srhu_rhepe_tot_pa_ventas
        srhu_rhepe_exp_pa_cxc
        srhu_rhepe_acc_pa_cxc
        srhu_rhepe_tot_pa_cxc
        srhu_rhepe_exp_pa_compras
        srhu_rhepe_acc_pa_compras
        srhu_rhepe_tot_pa_compras
        srhu_rhepe_exp_pa_cxp
        srhu_rhepe_acc_pa_cxp
        srhu_rhepe_tot_pa_cxp
        srhu_rhepe_exp_cl_clientes
        srhu_rhepe_acc_cl_clientes
        srhu_rhepe_exp_cl_prospectos
        srhu_rhepe_acc_cl_prospectos
        srhu_rhepe_exp_cl_contactos
        srhu_rhepe_acc_cl_contactos
        srhu_rhepe_exp_ve_cotizaciones
        srhu_rhepe_acc_ve_cotizaciones
        srhu_rhepe_tot_ve_cotizaciones
        srhu_rhepe_exp_ve_pedidos
        srhu_rhepe_acc_ve_pedidos
        srhu_rhepe_tot_ve_pedidos
        srhu_rhepe_exp_ve_facturas
        srhu_rhepe_acc_ve_facturas
        srhu_rhepe_tot_ve_facturas
        srhu_rhepe_exp_ve_envios
        srhu_rhepe_acc_ve_envios
        srhu_rhepe_tot_ve_envios
        srhu_rhepe_exp_ve_proyectos
        srhu_rhepe_acc_ve_proyectos
        srhu_rhepe_exp_ve_precios_productos
        srhu_rhepe_exp_ve_productos_facturados
        srhu_rhepe_exp_pt_movimientos
        srhu_rhepe_acc_pt_movimientos
        srhu_rhepe_exp_pt_productos
        srhu_rhepe_acc_pt_productos
        srhu_rhepe_exp_cxc_contrasenias
        srhu_rhepe_acc_cxc_contrasenias
        srhu_rhepe_tot_cxc_contrasenias
        srhu_rhepe_exp_cxc_cobros
        srhu_rhepe_acc_cxc_cobros
        srhu_rhepe_tot_cxc_cobros
        srhu_rhepe_exp_cxc_cobros_anticipos
        srhu_rhepe_acc_cxc_cobros_anticipos
        srhu_rhepe_tot_cxc_cobros_anticipos
        srhu_rhepe_exp_cxc_recibos_sin_deposito
        srhu_rhepe_acc_cxc_recibos_sin_deposito
        srhu_rhepe_tot_cxc_recibos_sin_deposito
        srhu_rhepe_exp_cxc_estado_cuenta
        srhu_rhepe_acc_cxc_estado_cuenta
        srhu_rhepe_tot_cxc_estado_cuenta
        srhu_rhepe_exp_cxc_antiguedad
        srhu_rhepe_acc_cxc_antiguedad
        srhu_rhepe_tot_cxc_antiguedad
        srhu_rhepe_exp_co_ordenes_locales
        srhu_rhepe_acc_co_ordenes_locales
        srhu_rhepe_tot_co_ordenes_locales
        srhu_rhepe_exp_co_ordenes_importacion
        srhu_rhepe_acc_co_ordenes_importacion
        srhu_rhepe_tot_co_ordenes_importacion
        srhu_rhepe_exp_co_facturas
        srhu_rhepe_acc_co_facturas
        srhu_rhepe_tot_co_facturas
        srhu_rhepe_exp_co_facturas_caja_chica
        srhu_rhepe_acc_co_facturas_caja_chica
        srhu_rhepe_tot_co_facturas_caja_chica
        srhu_rhepe_exp_co_facturas_especiales
        srhu_rhepe_acc_co_facturas_especiales
        srhu_rhepe_tot_co_facturas_especiales
        srhu_rhepe_exp_co_productos
        srhu_rhepe_acc_co_productos
        srhu_rhepe_exp_cxp_contrasenias
        srhu_rhepe_acc_cxp_contrasenias
        srhu_rhepe_exp_cxp_pagos
        srhu_rhepe_acc_cxp_pagos
        srhu_rhepe_tot_cxp_pagos
        srhu_rhepe_exp_cxp_pagos_anticipos
        srhu_rhepe_acc_cxp_pagos_anticipos
        srhu_rhepe_tot_cxp_pagos_anticipos
        srhu_rhepe_exp_cxp_proveedores
        srhu_rhepe_acc_cxp_proveedores
        srhu_rhepe_exp_cxp_estado_cuenta
        srhu_rhepe_acc_cxp_estado_cuenta
        srhu_rhepe_tot_cxp_estado_cuenta
        srhu_rhepe_exp_cxp_antiguedad
        srhu_rhepe_acc_cxp_antiguedad
        srhu_rhepe_tot_cxp_antiguedad
        srhu_rhepe_exp_ba_transacciones
        srhu_rhepe_acc_ba_transacciones
        srhu_rhepe_tot_ba_transacciones
        srhu_rhepe_exp_ba_liquidacion_cobros
        srhu_rhepe_acc_ba_liquidacion_cobros
        srhu_rhepe_tot_ba_liquidacion_cobros
        srhu_rhepe_exp_ba_caja_chica
        srhu_rhepe_acc_ba_caja_chica
        srhu_rhepe_tot_ba_caja_chica
        srhu_rhepe_exp_fi_movimientos
        srhu_rhepe_acc_fi_movimientos
        srhu_rhepe_tot_fi_movimientos
        srhu_rhepe_exp_con_nomenclatura
        srhu_rhepe_acc_con_nomenclatura
        srhu_rhepe_exp_rh_empleados
        srhu_rhepe_exp_rh_boletas_pago
        srhu_rhepe_acc_rh_boletas_pago
      }
    }
  }
`;

export const updateEmpleado = gql`
  mutation updateEmpleado($id: ID!, $data: UpdateEmpleadoInput!) {
    updateEmpleado(id: $id, data: $data)
  }
`;

export const updateEmpleadoPermiso = gql`
  mutation updateEmpleadoPermiso($id: ID!, $data: UpdateEmpleadoPermisoInput!) {
    updateEmpleadoPermiso(id: $id, data: $data)
  }
`;
