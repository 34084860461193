<template>
  <div>
    <v-card flat class="soft_shadow">
      <v-card-text>
        <v-row class="">
          <v-col cols="12" sm="12" md="6">
            <h2 class="font-weight-bold header--text d-flex align-end">
              <img
                v-if="imgUrl"
                :src="imgUrl"
                alt="alt"
                width=""
                style="max-width: 30px; opacity: 0.8"
              />
              <v-icon v-else color="headerColor" large left>
                {{ icon }}
              </v-icon>
              <span class="headerColor--text pb-1 mt-3">{{ title }}</span>
            </h2>
          </v-col>
          <v-col
            v-if="breadCrumbs"
            cols="12"
            sm="12"
            md="6"
            class="d-flex justify-md-end"
          >
            <BreadCrumbs :items-bread-crumbs="breadCrumbs"></BreadCrumbs>
          </v-col>
          <v-col v-if="text" cols="12" class="">
            <span class="support--text font-weight-medium">
              <p>{{ text }}</p>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import BreadCrumbs from "./partials/BreadCrumbs";
export default {
  name: "myHeaderView",
  props: {
    icon: String,
    imgUrl: String,
    title: String,
    text: String,
    breadCrumbs: Array,
  },
  components: {
    BreadCrumbs,
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped>
p {
  font-size: 0.8rem;
}
</style>
